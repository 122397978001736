import React, { useRef, useEffect, useState, useContext } from 'react'
import { navigate } from 'gatsby';
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AccordeonsProducts from '../components/AccordeonsProducts'
import SplitText from '../components/SplitText'
import SliderScroll from '../components/SliderScroll'
import AccordeonsSmall from '../components/AccordeonsSmall'
import Button from '../components/Button'
import { ContextLoader } from '../context';
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import smoothScrollTo from '../helpers/smoothScrollTo';
import Picture from '../components/Picture';

const MAX_ADDITIONAL_IMAGES = 3;

const OurProducts = ({ data }) => {

	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

	const prismicNavigation = data.prismicNavigation
	const prismicFooter = data.prismicFooter
	const pageData = data.prismicOurProducts.data
	const products = pageData.products.map(item => {
		const product = item.product.document.data;
		const productCategoriesFiltered = product.product_categories.reduce((result, itemCat) => {
			if (itemCat.product_category.document) {
				const newObj = itemCat.product_category.document.data;
				newObj.id = itemCat.product_category.document.id;
				newObj.slug = itemCat.product_category.document.uid
				result.push(newObj);
			}
			const projectsForProduct = product.projects_for_product.map(item => {
				if (item.project.document !== null && item.project.document !== undefined)
					return item.project.document.data
			  })
			product.projectsForProduct = projectsForProduct;
			return result;
		}, []);
		product.productCategoriesFiltered = productCategoriesFiltered;
		return product;
	})

	const selectRef = useRef(null)
	const switchContainerEl = useRef(null)
	const [isSelectReduced, setIsSelectReduced] = useState(false)
	const [currentProductIndex, setCurrentProductIndex] = useState(0)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	})

	const handleScroll = () => {
		const scrollY = window.scrollY
		const threshold = window.innerWidth * 0.2513;

		if (scrollY >= threshold && !isSelectReduced) {
			setIsSelectReduced(true)
		} else if (scrollY < threshold && isSelectReduced) {
			setIsSelectReduced(false)
		}
	}

	// Init current product content and option in select
	useEffect(() => {
		document.querySelector('.OurProducts__Option:first-child').classList.add('current');
		document.querySelector('.OurProducts__ProductContainer:first-child').classList.add('current');
	}, [])

	const handleOnKeyDown = e => {
		if (e.key === 'Enter') {
			updateCurrentProduct(e);
		}
	}

	const updateCurrentProduct = e => {
		const newIndex = parseInt(e.target.closest('[data-product-index]').getAttribute('data-product-index'))
		// Update select
		document.querySelector('.OurProducts__Option.current').classList.remove('current');
		[...document.querySelectorAll('.OurProducts__Option')][newIndex].classList.add('current')
		// Update product content
		document.querySelector('.OurProducts__ProductContainer.current').classList.remove('current');
		[...document.querySelectorAll('.OurProducts__ProductContainer')][newIndex].classList.add('current')

		// resetSwitchContainerHeight()
		setCurrentProductIndex(newIndex)
		smoothScrollTo(switchContainerEl.current, -150)
		setSmoothScrollingTo(null)
	}

	/*
	* Update container height when products container accordeons change page height,
	* We need to do that cause each product container is in position absolute
	*/
	const updateSwitchContainerHeight = (heightDifference) => {
		// const currentContainerHeight = switchContainerEl.current.getBoundingClientRect().height;
		// const newContainerHeight = currentContainerHeight + heightDifference;
		// switchContainerEl.current.style.height = newContainerHeight + 'px';
		resetSwitchContainerHeight();
	}

	const resetSwitchContainerHeight = () => {
		switchContainerEl.current.style.height = 'auto';
	}

	/**
	* On click on a category overview, smooth scroll to to the clicked category
	*/
	const [smoothScrollingTo, setSmoothScrollingTo] = useState(null)
	const handleCategoryOverviewClick = e => {
		const index = parseInt(e.target.closest('[data-category-index]').getAttribute('data-category-index'))
		const target = document.querySelector(`.OurProducts__ProductContainer.current .AccordeonsProducts__Item:nth-child(${index + 1})`)
		// smoothScrollTo(target, window.innerHeight * -0.3)
		setSmoothScrollingTo(index)
	}

	const currentProjectImages = pageData.slider_images.filter(item=> item.category.text == (currentProductIndex+1));

	const pageTitle = "Terracotta Exteriors, Decking, and Bricks | Shildan Group";
	const metaDescription = "Customizable terracotta rainscreens, teracotta sunscreens, teracotta cladding, and curtain walls come in a variety of colors so you your building can match your unique style.";
	const metaKeywords = "Terracotta sunscreens, Terracotta rainscreens, Terracotta cladding";

  return (
    <div>
      <SEO title={pageTitle} description={metaDescription} keywords={metaKeywords} />
			<Header
				currentPage="our_products"
				navigation={prismicNavigation}
				footer={prismicFooter}
				visibleOnlyOnTopOfPage={true}
				additionalClasses={isSelectReduced ? 'hasProductsMenu' : ''}
			/>
			<section className="OurProducts">

				<h1
					className="OurProducts__Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={pageData.banner_title.raw} />
					</SplitText>
				</h1>

				<div
					className="OurProducts__SelectContainer"
					data-is-on-screen
				>
					<ul className={`OurProducts__Select ${isSelectReduced ? 'OurProducts__Select--Reduced' : ''}`} ref={selectRef}>
						{products.map((product, index) => (
							<li
								className="OurProducts__Option"
								key={`our-products-option-${index}`}
							>
								<div
									className="OurProducts__OptionButton"
									onClick={updateCurrentProduct}
									onKeyDown={handleOnKeyDown}
									role="button"
									tabIndex={0}
									data-product-index={index}
								>
									<div className="OurProducts__OptionImageContainer">
										<Picture
											src={product.image.fluid.src}
											srcSet={product.image.fluid.srcSet}
											alt={product.image.alt}
											sizes="17.63vw"
											className="OurProducts__OptionImage"
											noDownload={true}
										/>
									</div>
									<h3 className="OurProducts__OptionName">
										<SplitText splitBy="chars">
											{product.title.text}
										</SplitText>
									</h3>
								</div>
							</li>
						))}
					</ul>
				</div>

				<div
					className="OurProducts__SwitchContainer"
					ref={switchContainerEl}
				>
					{products.map((product, productIndex) => (
						<div
							className="OurProducts__ProductContainer"
							key={`our-products-content-${productIndex}`}
						>
							<div className="OurProducts__ProductHeader">
								<h2
									className="OurProducts__ProductName"
									data-is-on-screen
								>
									<SplitText splitBy="lines" hasMask>
										{product.title.text}
									</SplitText>
								</h2>
								<div
									className="OurProducts__ProductDescription"
									data-is-on-screen
								>
									<SplitText splitBy="words">
										<RichText render={product.description.raw} />
									</SplitText>
								</div>
							</div>

							<ul
								className="OurProducts__ProductCategoriesOverview"
								data-is-on-screen
							>
								{product.productCategoriesFiltered.map((category, index) => {
									const slug = "/"+category.slug;
									let categoryElement = null;
									if (!product.only_show_additional_images)
										categoryElement = <li
										className="OurProducts__ProductCategoriesOverviewItem"
										key={`overview-image-${category.id}`}
										data-category-index={index}
										onClick={handleCategoryOverviewClick}
									>
											<span className="OurProducts__ProductCategoriesOverviewItemTitle">
												<SplitText splitBy="chars">
													{category.overview_title.text}
												</SplitText>
											</span>
											<div className="OurProducts__ProductCategoriesOverviewItemImageContainer">
												<Picture
													src={category.image.fluid.src}
													srcSet={category.image.fluid.srcSet}
													alt={category.image.fluid.alt}
													sizes="30.208vw"
													className="OurProducts__ProductCategoriesOverviewItemImage"
													linksTo={slug}
													linkClassName=""
													linkTarget=''
												/>
											</div>
										{/* https://stackoverflow.com/questions/47837494/event-bubbling-in-react-doesnt-stop-using-e-preventdefault */}
										</li>
										// console.log(category.overview_title.text,category)
										return (categoryElement)
									})
								}

								{product.product_add_images.map((add_image, index) => {
									const firstCategory = product.productCategoriesFiltered[0]
									const slug = "/"+firstCategory.slug;
									let maxAdditionalImages = (MAX_ADDITIONAL_IMAGES - product.productCategoriesFiltered.length);
									if (product.only_show_additional_images)
										maxAdditionalImages = MAX_ADDITIONAL_IMAGES;
									const image = add_image.image
										if (index < maxAdditionalImages)
										return <li
											className="OurProducts__ProductCategoriesOverviewItem"
											key={`overview-image-${firstCategory.id}`}
											data-category-index={index}
											onClick={handleCategoryOverviewClick}
										>
											<span className="OurProducts__ProductCategoriesOverviewItemTitle">
												<SplitText splitBy="chars">
													{add_image.title.text}
												</SplitText>
											</span>
											<div className="OurProducts__ProductCategoriesOverviewItemImageContainer">
												<Picture
													src={image.url}
													alt={image.alt}
													sizes="30.208vw"
													className="OurProducts__ProductCategoriesOverviewItemImage"
													linksTo={slug}
													linkClassName=""
													linkTarget=''
												/>
											</div>
										</li>;
									})
								}

								{/* RENDER PROJECTS ADDED FOR THE PRODUCT */}
								{product.projects_for_product.map((proj, index) => {
									let maxAdditionalImages = (MAX_ADDITIONAL_IMAGES - product.productCategoriesFiltered.length - product.product_add_images.length);
									
									// if: project info is blank there are already the max images, then: ignore projects
									if (proj.project.document === null || index >= maxAdditionalImages || product.only_show_additional_images)
										return null;

									const project = proj.project.document.data;
									const slug = "/"+proj.project.document.uid;
									return( 
									<li
										className="OurProducts__ProductCategoriesOverviewItem"
										key={`overview-image-${project.location.text}`}
										data-category-index={index}
										data-category-slug={slug}
										onClick={handleCategoryOverviewClick}
									>
										<span className="OurProducts__ProductCategoriesOverviewItemTitle">
											<SplitText splitBy="chars">
												{project.title.text}
											</SplitText>
										</span>
										<div className="OurProducts__ProductCategoriesOverviewItemImageContainer">
											<Picture
												src={project.image.fluid.src}
												srcSet={project.image.fluid.srcSet}
												alt={project.image.fluid.alt}
												sizes="30.208vw"
												className="OurProducts__ProductCategoriesOverviewItemImage"
											/>
										</div>
									</li>
									)
								})}
							</ul>
						</div>
					))}
				</div>

				<SliderScroll speed={1.25} className="OurProducts__SliderScroll">
					{currentProjectImages
						.map((item, index) => (
						<div
							className="OurProducts__SliderScrollSlideContainer"
							key={`slider-scroll-slide-${index}`}
						>
							<div className="OurProducts__SliderScrollImageContainer">
								<Picture
									className="OurProducts__SliderScrollImage"
									src={item.image.fluid.src}
									srcSet={item.image.fluid.srcSet}
									sizes="(max-width: 800px) 100vw, 64.23vw"
									alt={item.image.alt}
								/>
							</div>
							<p className="OurProduct__SliderScrollImageLabel">
								{item.label.text}
							</p>
						</div>
					))}
				</SliderScroll>

				<div className="OurProducts__AccordeonsSmalls">
					{products.map((product, index) => {
						if (product.small_accordion_infos.length === 0) return
						return (
							<div
								className={`OurProducts__AccordeonsSmallContainer ${index === currentProductIndex ? 'visible' : ''}`}
								key={`ourproducts-accordeons-small-${index}`}
							>
								<AccordeonsSmall
									items={product.small_accordion_infos}
									isVisible={index === currentProductIndex}
								/>
							</div>
						)
					})}
				</div>

				<div className="OurProducts__BottomInfos">
					<div className="OurProducts__BottomInfosTitle">
						<RichText render={pageData.bottom_title.raw} />
					</div>
					<div className="OurProducts__BottomInfosContent">
						<RichText render={pageData.bottom_content.raw} />
					</div>
					<Button
						link={pageData.bottom_button_link.url}
						modifier="normal"
						className="OurProducts__BottomInfosButton"
					>
						{pageData.bottom_button_text.text}
					</Button>
				</div>

			</section>

			<Footer footer={prismicFooter.data} showImage={false} />
    </div>
  )
}

export const query = graphql`
	query OurProducts {
		prismicOurProducts {
			data {
				banner_title {
					raw
				}
				products {
					product {
						document {
							... on PrismicProduct {
								id
								data {
									title {
										text
									}
									image {
										alt
										fluid {
											src
											srcSet
										}
									}
									description {
										raw
									}
									small_accordion_infos {
										title {
											text
										}
										content {
											raw
										}
									}
									only_show_additional_images
									product_add_images {
										image {
										alt
										fluid {
											src
											srcSet
										}
										url
										}
										title {
										text
										}
									}
									product_categories {
										product_category {
											document {
												... on PrismicProductCategory {
													id
													uid
													data {
														title {
															text
														}
														overview_title {
															text
														}
														image {
															alt
															fluid {
																src
																srcSet
															}
														}

														body {
															... on PrismicProductCategoryBodyParagraphs {
																id
																primary {
																	paragraph {
																		raw
																	}
																}
																slice_type
															}
															... on PrismicProductCategoryBodySideBySideImage {
																id
																slice_type
																primary {
																	image_left {
																		alt
																		fluid {
																			src
																			srcSet
																		}
																	}
																	image_left_2 {
																		alt
																		fluid {
																			src
																			srcSet
																		}
																	}
																	image_left_caption {
                                  	text
                                	}
																	image_right {
																		alt
																		fluid {
																			src
																			srcSet
																		}
																	}
																	image_right_2 {
																		alt
																		fluid {
																			src
																			srcSet
																		}
																	}
																	image_right_caption {
                                  	text
                                	}
																}
															}
															... on PrismicProductCategoryBodyFullWidthImage {
																id
																slice_type
																primary {
																	image1 {
																		alt
																		fluid {
																			src
																			srcSet
																		}
																	}
																}
															}
															... on PrismicProductCategoryBodyButton {
																id
																slice_type
																primary {
																	text {
																		text
																	}
																	link {
																		type
																		uid
																		url
																	}
																}
															}
															... on PrismicProductCategoryBodySubtitleAndParagraph {
																id
																slice_type
																primary {
																	subtitle {
																		text
																	}
																	paragraph {
																		raw
																	}
																}
															}
															... on PrismicProductCategoryBodyDesignCustomization {
																id
																slice_type
																primary {
																	is_activated
																	intro_image {
																		alt
																		fluid {
																			src
																			srcSet
																		}
																	}
																}
																items {
																	type
																	label
																	image1 {
																		alt
																		fluid {
																			src
																			srcSet
																		}
																	}
																}
															}
															... on PrismicProductCategoryBodyImagesSlider {
																id
																slice_type
																id
																slice_type
																items {
																  ... on PrismicProductCategoryBodyImagesSliderItemType {
																	image {
																	  ... on PrismicImageType {
																		url
																	  }
																	}
																	title {
																	  text
																	}
																  }
																}
																primary {
																  ... on PrismicProductCategoryBodyImagesSliderPrimaryType {
																	slider_title {
																	  ... on PrismicStructuredTextType {
																		text
																	  }
																	}
															  
																	slider_description {
																	  ... on PrismicStructuredTextType {
																		text
																	  }
																	}
															  
																	button_name {
																	  ... on PrismicStructuredTextType {
																		text
																	  }
																	}
															  
																	button_link {
																	  ... on PrismicStructuredTextType {
																		text
																	  }
																	}
																  }
																}
															  }
														}
													}
												}
											}
										}
									}
									projects_for_product {
										project {
										  document {
											... on PrismicSelectedProject {
											  type
											  uid
											  data {
												title {
												  text
												}
												location {
												  text
												}
												image {
												  alt
												  url
												  fluid {
													src
													srcSet
												  }
												}
											  }
											}
										  }
										}
									  }
								}
							}
						}
					}
				}
				slider_images {
					image {
						alt
						fluid {
							src
							srcSet
						}
					}
					label {
						text
					}
					category {
						text
					}
				}
				bottom_title {
					raw
				}
				bottom_content {
					raw
				}
				bottom_button_text {
					text
				}
				bottom_button_link {
					type
					uid
					url
				}
			}
		}
		prismicNavigation {
      ...HeaderQuery
    }
		prismicFooter {
			...FooterQuery
		}
	}
`
export default OurProducts