import { useEffect, useState, useContext } from 'react';
import { ContextLoader } from '../context';

// https://usehooks.com/useOnScreen/
function useOnScreen(ref, rootMargin = '0px') {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

	const [isLoading] = useContext(ContextLoader);

  useEffect(() => {
		if (isLoading === true) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
				// Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, [isLoading]); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}

export default useOnScreen;