import React, { useRef, useEffect, useState } from 'react';
import { RichText } from 'prismic-reactjs'

const AccordeonsSmall = ({ items, isVisible = true }) => {

	const [currentIndex, updateCurrentIndex] = useState(null);
	const itemsEls = useRef([]);

	// Set height depending on content height
	useEffect(() => {
		if (isVisible === false) return
		itemsEls.current.forEach(itemEl => {
			const closedHeight = itemEl.querySelector('.AccordeonsSmall__ItemHeader').getBoundingClientRect().height;
			const contentEl = itemEl.querySelector('.AccordeonsSmall__ItemContent');
			const contentElHeight = contentEl.getBoundingClientRect().height;
			const openHeightInPx = closedHeight + contentElHeight;
			const openHeightInVw = 100 * (openHeightInPx / window.innerWidth);
			itemEl.style.height = closedHeight + 'px';
			itemEl.setAttribute('data-closed-height', closedHeight);
			itemEl.setAttribute('data-open-height', openHeightInVw);
		})
	}, [isVisible])

	const handleOnKeyDown = e => {
		if (e.key === 'Enter') {
			onItemClick();
		}
	}

	const onItemClick = e => {
		const previousItem = itemsEls.current[currentIndex];
		if (previousItem) {
			previousItem.classList.remove('current');
			previousItem.style.height = previousItem.getAttribute('data-closed-height') + 'px';
		};

		const newIndex = e.target.closest('[data-accordeon-small-index]').getAttribute('data-accordeon-small-index');
		const newItem = itemsEls.current[newIndex];

		if (currentIndex === newIndex) {
			updateCurrentIndex(null);
		} else {
			updateCurrentIndex(newIndex);
			newItem.classList.add('current');
			newItem.style = `height: ${newItem.getAttribute('data-open-height')}vw;`;
		}
	}


	return (
		<section className="AccordeonsSmall">
			{items.map((item, index) => (
				<div
					className="AccordeonsSmall__Item"
					data-accordeon-small-index={index}
					onClick={onItemClick}
					onKeyDown={handleOnKeyDown}
					role="button"
					tabIndex={0}
					ref={ref => itemsEls.current[index] = ref}
					key={`accordeon-small-item-${index}`}
				>
					<div className="AccordeonsSmall__ItemHeader">
						<h4 className="AccordeonsSmall__ItemHeaderTitle">
							{item.title.text}
						</h4>
						<svg className="AccordeonsSmall__ItemHeaderArrow" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)" stroke="#fff" strokeWidth="1.5"><path d="M20 10.17H2.03M10.29 1.16L1.313 10.17l8.977 9.008"/></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h20v19.354H0z"/></clipPath></defs></svg>
					</div>
					<div className="AccordeonsSmall__ItemContent">
						<RichText render={item.content.raw} />
					</div>
				</div>
			))}
		</section>
	)
}

export default AccordeonsSmall;